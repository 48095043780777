<template>
<div class="page">
  <h1 id="logo">Ayyoub Bouzerda</h1>
  <p class="links">
    <a href="https://www.instagram.com/aybzrda/" target="_blank">Instagram</a>
    <a href="https://www.twitter.com/aybzrda/" target="_blank">Twitter</a>
    <a href="https://join.satoshibles.com" target="_blank">Discord</a>
    <a href="https://opensea.io/collection/aybzrda" target="_blank">OpenSea</a>
  </p>
</div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss">
@import "../css/index.scss";
</style>